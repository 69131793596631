<template>
  <b-row>
    <b-col md="12">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="float-right mb-1"
        onclick="history.back()"
      >
        <feather-icon icon="ArrowLeftCircleIcon" />
        Retour
      </b-button>
    </b-col>
    <b-col md="12">
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >

        <!-- general tab -->
        <b-tab active>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Général</span>
          </template>

          <b-card no-body>
            <b-card-body>
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  active
                >
                  <span>Informations de l'utilisateur</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span>Nom de l'interessé</span>
                  {{ expert.user.lastname }}
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span>Numéro de téléphone</span>
                  {{ expert.user.telephone }}

                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span>Adresse e-mail</span>
                  {{ expert.user.email }}
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span>Moyenne sur 10</span>
                  {{ expert.rating }}
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span>Domaine d'expertise</span>
                  {{ expert.categorie_expertise.libelle }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-card>
        </b-tab>
        <!--/ general tab -->

        <!-- Modifier mot de passe tab -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="FileIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Commentaires</span>
          </template>
          <!-- form -->
          <b-card>
            <b-list-group>
              <b-list-group-item
                v-for="(data, index) in expert.appreciations"
                :key="index"
                class="d-flex flex-column justify-content-between align-items-center"
              >
                <span
                  class="d-flex w-100 justify-content-between align-items-center"
                >
                  <span>Date:</span>
                  {{ data.created_at }}
                </span>
                <span
                  v-if="data.commentaire"
                  class="d-flex w-100 flex-column justify-content-between align-items-start"
                >
                  <span>Commentaire</span><br>
                  {{ data.commentaire }}
                </span>
                <span
                  v-else
                  class="d-flex w-100 flex-column justify-content-between align-items-start"
                >
                  <span>Commentaire</span>
                  Aucun commentaire
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-tab>
        <!--/ Modifier mot de passe tab -->

      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import { required } from '@validations'

import {
  BTabs, BTab, BButton, BRow, BCol, BCard, BListGroup, BListGroupItem, BCardBody,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useTypeExperts from '@/services/expertise/typeExpertService'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      expert, rating, getExpertById, errors, typeExpertSuccess, getExpertRating, typeExpert, loader,
    } = useTypeExperts()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      libelle: '',
      description: '',
    })
    /* eslint-disable global-require */
    const items = expert

    onMounted(() => {
      getExpertById()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      onFiltered,
      expert,
      form,
      getExpertRating,
      errors,
      typeExpertSuccess,
      required,
      rating,
      formatter,
      typeExpert,
      getExpertById,
      loader,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.storeTypeExpert()
          if (this.typeExpertSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
